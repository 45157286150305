import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import ApiService from './common/api.service';

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';


import './assets/css/main.scss';


const pinia = createPinia()
const app = createApp(App)
ApiService.init();
app.use(router)
.use(pinia)
.mount('#app')

app.component('EasyDataTable', Vue3EasyDataTable);
