<template>
  <div class="nav">
    <router-link :to="{name:'list-category'}" class="nav-link active" aria-current="page" href="#">Category</router-link>
    <router-link :to="{name:'list-picture'}" class="nav-link active ml4" aria-current="page" href="#">Picture</router-link>
  </div>
</template>
<style lang="scss">
.nav {
    height: 60px;
    display: flex;
    justify-content: start;
}
</style>