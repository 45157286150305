import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue'; 

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: '/',
    redirect: '/login' // Redirect the root path to the login page
  },
  {
    component: DefaultLayout, 
    children: [
      {
        path: '/picture',
        component: () => import('@/pages/picture/index.vue'),
        name: 'list-picture'
      },
      {
        path: '/picture/detail/:id', 
        component: () => import('@/pages/picture/Detail.vue'),
        name: 'detail-picture'
      },
      {
        path: '/picture/create', // Đã điều chỉnh path để nối tiếp với '/picture'
        component: () => import('@/pages/picture/Create.vue'),
        name: 'create-picture'
      }
    ]
  },
  {
    component: DefaultLayout,
    children:[
        {
          path: '/category',
          component: () => import('@/pages/category/index.vue'),
          name: 'list-category'
        },
        {
          path: '/category/detail/:id',
          component: () => import('@/pages/category/Detail.vue'),
          name: 'detail-category'
        },
        {
          path: '/category/create',
          component: () => import('@/pages/category/Create.vue'),
          name: 'create-category'
        }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;