import axios from "axios";
import router from '@/router';
import JwtService from "@/common/jwt.service";

const url = process.env.VUE_APP_API_URL;

const base = axios.create({
    baseURL: url
});

base.interceptors.response.use(function (response) {
    const { data } = response
    if (data.status == 401) {
        router.push({ name: "Login" });
    } else {
        return response;
    }
}, function (error) {
    return Promise.reject(error);
});

const ApiService = {
    init() {
        this.setHeader();
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        const token = JwtService.getToken();
        if (token) {
            base.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    },

    query(resource, params) {
        this.setHeader();
        return new Promise(resolve => {
            base.get(`${resource}`, {params}).then((data) => {
                resolve(data);
            })
            .catch(error => {
                throw new Error(`[KT] ApiService ${error}`);
            });
        });
    },

    get(resource, slug = "") {
        this.setHeader();
        return new Promise(resolve => {
            base.get(`${resource}/${slug}`).then((data) => {
                resolve(data);
            }).catch(error => {
                throw new Error(`[KT] ApiService ${error}`);
            });
        });
    },

    post(resource, params, config = null) {
        this.setHeader();
        return new Promise(resolve => {
            base.post(`${resource}`, params, config).then((data) => {
                resolve(data);
            }).catch(error => {
                throw new Error(`[KT] ApiService ${error}`);
            });
        });
    },

    update(resource, slug, params) {
        this.setHeader();
        return new Promise(resolve => {
            base.put(`${resource}/${slug}`, params).then((data) => {
                resolve(data);
            }).catch(error => {
                throw new Error(`[KT] ApiService ${error}`);
            });
        });
    },

    put(resource, params) {
        this.setHeader();
        return new Promise(resolve => {
            base.put(`${resource}`, params).then(({data}) => {
                resolve(data);
            }).catch(error => {
                throw new Error(`[KT] ApiService ${error}`);
            });
        });
    },

    delete(resource, param) {
        this.setHeader();
        return new Promise(resolve => {
            base.delete(`${resource}/${param}`).then(({data}) => {
                resolve(data);
            }).catch(error => {
                throw new Error(`[RWV] ApiService ${error}`);
            });
        });
    },

    patch(resource, params) {
        this.setHeader();
        return new Promise(resolve => {
            base.patch(`${resource}`, params).then(({data}) => {
                resolve(data);
            }).catch(error => {
                throw new Error(`[RWV] ApiService ${error}`);
            });
        });
    }
};

export default ApiService;